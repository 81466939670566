import { createContext, useContext, useEffect, ReactNode, useCallback } from 'react';
import useStorage from '../../hooks/useStorage';

type ThemeContextType = {
    isDarkMode: boolean;
    toggleTheme: () => void;
};

declare global {
    interface Window { applyDarkTheme: () => void; }
}

const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

const STORE_SCOPE_KEY = 'theme';
const STORE_KEY = 'mode';

/*
Dark theme handling is implemented as follows:

- In index.html:
    - The Mosaic dark theme stylesheet is linked and initially disabled.
    - A script defines the function `applyDarkTheme` on the `window` object.
        - `applyDarkTheme` determines the preferred theme mode either from blob storage (key: `theme:mode`) or the user's system preference.
        - It also observes changes to the system preference and re-applies the theme accordingly (when no value is defined in blob storage).
    - The `body` tag background is explicitly set to the primary background color to match the selected theme and avoid visual inconsistencies during load.

- In ThemeContext:
    - We update the value in blob storage when the user changes their theme preference.
    - `applyDarkTheme` is invoked whenever the theme value changes.

Rationale:
- Why is this logic placed in `index.html`?
    - To avoid visual flickering or a "flash of incorrect theme" during the initial page load.

- Why do we rely on blob storage instead of local state?
    - To ensure the selected theme is synchronized across all open browser tabs.
*/

export const ThemeProvider = ({ children }: { children: ReactNode; }) => {
    const { useStorageState } = useStorage(STORE_SCOPE_KEY, 'local', 'json');
    const [themeMode, setThemeMode] = useStorageState<'auto' | 'dark' | 'light'>(
        STORE_KEY,
        'auto' // Default value
    );

    const isDarkMode = themeMode === 'dark';
    useEffect(() => window.applyDarkTheme, [themeMode]);

    const toggleTheme = useCallback(() => setThemeMode(current => current === "dark" ? 'light' : 'dark'), [setThemeMode]);

    return (
        <ThemeContext.Provider value={{ isDarkMode, toggleTheme }}>
            {children}
        </ThemeContext.Provider>
    );
};

export const useTheme = (): ThemeContextType => {
    const context = useContext(ThemeContext);
    if (!context) {
        throw new Error('useTheme must be used within a ThemeProvider');
    }
    return context;
};