import Chip from '../chip/Chip';
import { forwardRef } from 'react';
import { DfMenuItem } from '@danfoss/mosaic-react';
import { getClassNames } from '../../helpers/classHelpers';
import { wrapInOptionalLink } from '../../helpers/linkHelpers';
import { Icon } from '../icon/Icon';
import { IconTheme } from '../icon/Icon.types';
import { ListItemContentProps } from './List';
import style from './List.module.scss';

type Props = {
    props?: React.HTMLAttributes<HTMLElement> | undefined;
    className?: string;
    children?: React.ReactNode;
    item: ListItemContentProps;
};
const ListItemContent = forwardRef<HTMLLIElement, Props>(({ className, props, children, item }, ref) => {
    const { title, url, hoverIcon, linkProps, id, icon, hoverIconClassName, onClick, onHoverIconClick } = item || {};

    let content = <DfMenuItem title={title} className={getClassNames([style['list-item']])} onClick={onClick}>
        <div className={style.inner}>
            {icon && <Icon.Adaptive className={style.icon} theme={IconTheme.dark} iconName={icon} size={20} title='' />}
            <span className={style.title}>{title}</span>
            {hoverIcon && <div className={hoverIconClassName}><Chip onClick={onHoverIconClick} className={getClassNames([style.chip])} icon={hoverIcon} /></div>}
            {children}
        </div>
    </DfMenuItem>;

    content = wrapInOptionalLink(content, url, linkProps);

    return <li ref={ref} key={id} id={id} {...props} className={getClassNames([className, item.className, item.isActive && style.active])} >{content}</li>;
});

export default ListItemContent;