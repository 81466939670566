import MarkdownWrapper from "../../../../MarkdownWrapper";
import useLabels from "../../../../../../hooks/useLabels";
import useEffectAsync, { CancellationToken } from "../../../../../../hooks/useEffectAsync";
import Loader from "../../../../../loader/Loader";
import Accordion from "../../../../../accordion/Accordion";
import ErrorBoundary from "../../../../../../error/ErrorBoundary";
import { FC, ReactNode, useCallback, useMemo, useState } from "react";
import { ChatMessageTraceResponse } from "../../../../../../models/ChatTypes";
import { useChat } from "../../../../../../contexts/chat/ChatContext";
import { getClassNames } from "../../../../../../helpers/classHelpers";
import { isString } from "../../../../../../helpers/typeHelpers";
import { ErrorHandler } from "../../../../../../contexts/error-handler/ErrorHandler";
import { useErrorHandlerContext } from "../../../../../../contexts/error-handler/ErrorContext";
import { TestIds } from '../../../../../../mocks/ids';
import styles from "./Trace.module.scss";
import { wrapAsMdJson } from '../../../../../../helpers/objectHelper';


type TraceProps = { traceId: string; };
export const Trace: FC<TraceProps> = ({ traceId }) => {
    const { messageInsights } = useChat();
    const labels = useLabels();
    const [insights, setInsights] = useState<ChatMessageTraceResponse[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const { errorId, getError, registerError, removeError } = useErrorHandlerContext();
    const error = getError(errorId);

    const loader = useCallback(async (token: CancellationToken | undefined) => {
        if (errorId) removeError(errorId);

        try {
            setIsLoading(true);

            const trace = await messageInsights(traceId);
            if (token?.cancelled) return;

            setInsights(trace?.traces ?? []);
        } catch (err: any) {
            if (token?.cancelled) return;
            registerError({ [errorId]: { type: 'notification', headline: labels.loadingErrorDescription, details: err } });

        } finally {
            setIsLoading(false);
        }
    }, [traceId, errorId, labels, messageInsights, registerError, removeError]);

    useEffectAsync(token => () => {
        if (!traceId) return;
        loader(token);
    }, [traceId, loader]);


    const wrapWithContainer = useCallback((children: ReactNode) => <div data-testid={TestIds.traceContainer}>{children}</div>, []);

    if (error) return wrapWithContainer(<ErrorHandler.Notification id={errorId} />);

    if (isLoading) return wrapWithContainer(<Loader />);

    return wrapWithContainer(<ol className={styles.insights}>
        {insights.map((insight, index) => <MessageInsight
            key={index}
            insight={insight}
            isLast={index === insights.length - 1}
        />)}
    </ol>);
};

const SafeMarkdown = ({ children }: { children: React.ReactNode; }) => {
    return <ErrorBoundary fallback={<>Unable to display this content</>}>{children}</ErrorBoundary>;
};

type MessageInsightProps = { insight: ChatMessageTraceResponse; isLast?: boolean; };
const MessageInsight: FC<MessageInsightProps> = ({ insight: { headline, artifact, description, input, output, result } }) => {
    const labels = useLabels();

    const contents = useMemo(() => [
        [labels.input, input],
        [labels.output, output],
        [labels.artifacts, artifact],
    ] as [string, string?][], [labels, input, output, artifact]);

    const accordionContent = useMemo(() => contents.map(([label, content], index) => <SafeMarkdown key={index}><MessageInsightElement label={label} content={content} /></SafeMarkdown>), [contents]);
    const hasAccordionContent = contents.some(([_, content]) => content);

    return <li className="df-timeline-steps">
        <div className="df-timeline-container">
            <div className="df-timeline">
                <div className="df-timeline-circle" />
                <div className="df-timeline-line" />
            </div>
        </div>
        <div className={getClassNames(['df-timeline-content', styles.insight])} style={{ flex: 1 }}>
            {headline && <h3>{headline}</h3>}
            {(description) && <p>{description}</p>}

            {result && <div className={styles.content}><SafeMarkdown>{result}</SafeMarkdown></div>}

            {hasAccordionContent && <Accordion
                className={styles.accordion}
                headline={labels.displayTechnicalDetails}
                content={accordionContent}
            />}
        </div>
    </li>;
};

type MessageInsightElementProps = { label: string; content?: string; };
const MessageInsightElement: FC<MessageInsightElementProps> = ({ label, content }) => {
    if (!content || (isString(content) && !content?.trim())) {
        return null;
    }
    return <div className={styles['content-wrapper']} data-testid={TestIds.traceView}>
        <b>{label}</b>
        <MarkdownWrapper markdown={isString(content) ? content : wrapAsMdJson(content)} />
    </div>;
};

