import * as svgIcons from "../icons/svg";
import { useMemo } from "react";
import { SvgIconProps } from "../Icon.types";
import { getClassNames } from "../../../helpers/classHelpers";
import styles from "../Icon.module.scss";
import { guid } from '../../../helpers/randomHelpers';

export default function Svg(props: SvgIconProps) {
    const { testId, isDisabled, isClickable, className, iconName, title, size = 24, borderColor = 'blue', borderSize = 0, theme, fill = 'red', style, onClick } = props;
    const key = useMemo(guid, []);
    const icon = useMemo(() => {
        const Node = svgIcons[iconName];

        const clickable = ((onClick || isClickable));
        return <span
            key={key}
            className={styles.icon}>
            <Node
                id={testId}
                title={title}
                fill="currentColor"
                style={style}
                tabIndex={(onClick || isClickable) ? 0 : undefined}
                onClick={onClick}
                className={
                    getClassNames([
                        className,
                        styles.icon,
                        styles[fill],
                        clickable && styles.clickable,
                        size && styles[`size-${size}`],
                        fill && styles[`fill-${fill}`],
                        theme && styles[`theme-${theme}`],
                        !!borderSize && styles[`border-size-${borderSize}`],
                        borderColor && styles[`border-color-${borderColor}`]
                    ])
                }
                aria-disabled={isDisabled}
            />
        </span>;
    }, [key, borderColor, borderSize, className, fill, iconName, isClickable, isDisabled, onClick, size, style, testId, theme, title]);

    return icon;
}