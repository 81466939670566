import Adaptive from './Adaptive';
import { Icons } from '../icons/material';
import { SvgIcons } from '../icons/svg';
import { IconTheme, SelectorIconProps } from '../Icon.types';
import { getClassNames } from '../../../helpers/classHelpers';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { generateUniqueString } from '@danfoss/mosaic-core';
import { withUIState } from '../../../contexts/ui-state/withUIState';
import { useUIState } from '../../../contexts/ui-state/hooks';
import styles from '../Icon.module.scss';

function Selector({ isDisabled, isLoading, currentSelection, newSelection, onSelectionChange }: SelectorIconProps) {
    const id = useMemo(() => "_" + generateUniqueString(), []);// Some of generated string starts with number in front and as a result querySelector crashes. Thus adding underscore in front of the generated string.
    const containerRef = useRef<HTMLDivElement>(null);
    const iconMap = useMemo(() => Object.values(Icons).map(icon => ({ value: icon, label: icon })), []);
    const svgMap = useMemo(() => Object.values(SvgIcons).map(icon => ({ value: icon, label: icon })), []);
    const icons = useMemo(() => [...svgMap, ...iconMap], [iconMap, svgMap]);

    const handleSelect = useCallback((value: string) => { if (!currentSelection) return; onSelectionChange(value); }, [currentSelection, onSelectionChange]);

    // Scroll to selected element
    useEffect(() => {
        setTimeout(() => {
            if (!containerRef.current) return;
            const selectedElement = containerRef.current.querySelector(`#${id}.${styles.selected}`) as HTMLDivElement;
            if (!selectedElement) return;

            const containerHalfWidth = containerRef.current.offsetWidth / 2;
            const selectedElementHalfWidth = selectedElement.offsetWidth / 2;
            const left = (selectedElement.offsetLeft - containerHalfWidth) + selectedElementHalfWidth;
            containerRef.current?.scrollTo({ left });
        }, 100);
    }, [id, currentSelection]);

    // #marius: not sure if we can extend the icons, so maybe we can do it manually here? any ideas?
    const { isDisabled: uiStateDisabled, isLoading: uiStateLoading } = useUIState();
    const disabled = isDisabled || isLoading || uiStateDisabled || uiStateLoading;

    return (
        <div className={getClassNames([styles['icon-selector'], disabled && styles['disabled-selector']])}>
            <div ref={containerRef} className={styles['content']}>
                {icons.map((icon, index) => (<div id={id} key={index} className={getClassNames([styles['icon-wrapper'], currentSelection === icon.value && styles.selected, newSelection === icon.value && styles['new-selected']])} title={icon.value}
                    onClick={() => !disabled && handleSelect(icon.value)}>
                    <Adaptive theme={IconTheme.dark} key={index} iconName={icon.value} title={''} isDisabled={disabled} />
                </div>))}
            </div>
        </div>
    );
}

export default withUIState(Selector);