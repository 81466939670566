import useInfo from './useInfo';
import useLabels from './useLabels';
import { useMemo } from 'react';
import { useAuth } from '../contexts/auth/AuthContext';
import { advancedAssistantKey, defaultPersonaIcon } from '../constants/consts';
import { getAvatarHuman } from '../helpers/avatarHelpers';
import { ChatMessageResponse } from '../models/ChatTypes';

const wrapVersion = (version?: string, build?: number | undefined) => {
    let text = "";
    // if (label)
    //     text = label;
    if (version) {
        text = version;
        if (build)
            text = `${text}-${build}`;
    }
    return text ? `(v${text})` : "";
};

export default function useMessageProps(type: ChatMessageResponse['type'], llm?: string, persona?: string, version?: string, build?: number) {
    const { user } = useAuth();
    const labels = useLabels();
    const { getLlmLabelFromKey, getPersonaVersion } = useInfo();

    const props = useMemo(() => {

        const isGpt = type === 'ai';

        let isAlpha = false;
        let isBeta = false;
        let senderName = "";
        let senderExtraName = "";
        let senderAvatar = "";

        if (isGpt) {
            senderAvatar = defaultPersonaIcon;

            if (persona === advancedAssistantKey && llm) {
                senderName = labels.advancedAssistantTitle;
                senderExtraName = getLlmLabelFromKey(llm);
            }
            else if (persona) {
                const p = getPersonaVersion?.(persona, version, build);
                isAlpha = p.revision?.alpha ?? false;
                isBeta = p.revision?.beta ?? false;
                senderName = p?.persona?.title ?? `${labels.advancedAssistantTitle} (${persona})`;
                senderExtraName = wrapVersion(version, build); //p.revision?.label, 
                senderAvatar = p.persona?.avatar ?? senderAvatar;
            }
        }
        else {
            senderAvatar = getAvatarHuman(user);
            senderName = user?.name || user?.username || '';
        }

        return {
            isGpt,
            senderAvatar,
            senderName,
            isAlpha,
            isBeta,
            senderExtraName,
        };
    }, [type, labels.advancedAssistantTitle, persona, version, build, llm, getPersonaVersion, getLlmLabelFromKey, user]);

    return props;
}