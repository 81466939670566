import { FC, PropsWithChildren } from 'react';
import { QueryClient, QueryClientProvider, } from '@tanstack/react-query';
import { defaultQueryOptions } from './helpers/helpers';
import { GLOBAL_MODAL_ERROR_ID, useErrorHandlerContext } from '../../contexts/error-handler/ErrorContext';
import { isString } from '../../helpers/typeHelpers';


export let queryClientInstance: QueryClient | null = null;

const useQueryClient = () => {
    const { registerError } = useErrorHandlerContext();
    const handleRegisterError = ({ title, headline, description, error }: { title: string, headline: string, description?: string, error?: any; }) => registerError({ [GLOBAL_MODAL_ERROR_ID]: { type: 'modal', headline: title, notification: { headline, description, details: error } } });

    if (!queryClientInstance) return queryClientInstance = new QueryClient({
        defaultOptions: {
            queries: defaultQueryOptions,

            mutations: {
                ...defaultQueryOptions,
                onError: (error: any, vars: any, context: any) => {
                    const { status, statusText, data } = error || {};
                    console.log('Mutation error:', { error, vars, context });

                    const registerError = (title: string) => handleRegisterError({
                        error,
                        title,
                        headline: statusText,
                        description: data?.message ?? getErrorDescription(error) ?? 'Unknown Error',
                    });

                    if (status >= 500) registerError('Server Error');
                    else if (status >= 400 && status < 500) registerError('Client Error');
                    else registerError('Unknown Error');
                },
            }

            // defaultOptions: {
            //     queries: defaultQueryOptions,
            //     mutations: defaultQueryOptions,
            // },
            // queryCache: new QueryCache({
            //     onError: (error, query) => {
            //         console.error('Query error:', { error, query });
            //     }
            // }),
            // mutationCache: new MutationCache({
            //     onError: (error: any, vars, context) => {
            //         console.log('Mutation error:', { error, vars, context });
            //     }
            // })
        },
    });

    return queryClientInstance;
};

const getErrorDescription = (error: any) => {
    const detail = error?.data?.detail;
    if (!detail)
        return undefined;
    if (isString(detail))
        return detail;

    return detail.map((d: any) => d?.message || d?.msg).join('. ');
};

export const AppQueryProvider: FC<PropsWithChildren> = ({ children }) => {
    const queryClient = useQueryClient();
    return <QueryClientProvider client={queryClient}>
        {children}
    </QueryClientProvider>;
};