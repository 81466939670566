import App from './App';
import React from 'react';
import ReactDOM from 'react-dom/client';
import ContextBootstrap from './contexts/bootstrap/ContextBootstrap';
import ContextBuffer from './contexts/bootstrap/ContextBuffer';
import { AppQueryProvider } from './shared/react-query';
import { setNonce } from "@danfoss/mosaic-core/loader";
import { BrowserRouter } from 'react-router-dom';
import { ErrorHandler } from './contexts/error-handler/ErrorHandler';

// Mosaic (and styles)
import '@danfoss/mosaic-css/dist/mosaic.css';
import './index.scss';
import 'material-icons/iconfont/material-icons.css';
import { ThemeProvider } from './contexts/theme/ThemeContext';


const { mosaic } = require('./constants/nonce');
setNonce(mosaic);

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <ErrorHandler>
                <ThemeProvider>
                    <AppQueryProvider>
                        <ContextBootstrap>
                            <ContextBuffer>
                                <App />
                            </ContextBuffer>
                        </ContextBootstrap>
                    </AppQueryProvider>
                </ThemeProvider>
            </ErrorHandler>
        </BrowserRouter>
    </React.StrictMode >
);