import axios, { AxiosProgressEvent } from 'axios';
import TaskPromise from './TaskPromise';
import type { AuthTokenResponse, IHTTPDataService } from './IHTTPDataService';
import { fileUploadErrors } from '../data/labels';

export abstract class HTTPDataService implements IHTTPDataService {
    abstract baseURL: string;

    abstract getAccessToken(): Promise<string | undefined>;

    toAbsoluteApiUrl(endpoint: string, params?: Record<string, string>) {
        let url = endpoint;
        if (
            url.indexOf("http") !== 0 &&
            url.indexOf("//") !== 0 &&
            url.indexOf("/") !== 0
        ) {
            url = this.baseURL + "/" + url;
        }

        if (!params) return url;
        if (url.indexOf('?') === -1) url += "?";
        url += Object.keys(params).map(key => key + '=' + params[key]).join('&');

        return url;
    }

    promiseTokenCache: Promise<AuthTokenResponse | undefined> | undefined;
    cacheToken: AuthTokenResponse | undefined;
    private async getAuthToken() {
        const token = await this.getAccessToken();
        if (!token) return undefined;
        return { token };
    }

    getAcceptLanguageValue = () => {
        const languages = [this.getPreferredLanguage(), ...navigator.languages];
        let q = 1;
        return languages
            .filter(x => !!x)
            .slice(0, 10)
            .map(l => `${l};q=0.${10 - q++}`)
            .join(",");
    };
    getPreferredLanguage = () => localStorage.getItem("language");
    setPreferredLanguage = (language: string) => localStorage.setItem("language", language);

    private request<ResponseType>(method: 'GET' | 'POST' | 'PATCH' | 'DELETE', endpoint: string, params?: Record<string, string | number>, data?: any, onUploadProgress?: (progressEvent: AxiosProgressEvent) => void): TaskPromise<ResponseType> {

        const cancelTokenSource = axios.CancelToken.source();
        const promise = new TaskPromise<ResponseType>(async (resolve, reject) => {
            const url = this.toAbsoluteApiUrl(endpoint);

            let attemptCount = 0;
            const maxAttemptCount = 1;
            const call = async () => {
                // The number of times the `call` function has been triggered.
                attemptCount++;

                const handleError = (error: any, reattempt: boolean) => {
                    const response = error.response || error;

                    if (axios.isCancel(error)) {
                        reject('cancellationError');
                        return;
                    }

                    const statusText = response.statusText || response?.data?.Message || response?.data?.message || '';

                    // retry if i get 401 and i have a token 
                    if (!reattempt || !token || response.status !== 401) {
                        const error: HttpError = {
                            status: response.status || 0,
                            statusText,
                            data: response.data,
                            config: response.config,
                        };
                        reject(error);
                    }
                    else call();
                };

                let token: string | undefined = undefined;

                try {
                    token = (await this.getAuthToken())?.token; // force token refresh if this is not the first attempt.
                } catch (err: any) {
                    handleError(err, false); // do not reattempt in this case. The token call has failed.
                    return;
                }

                const headers: Record<string, string> = {
                    'Accept-Language': this.getAcceptLanguageValue(),
                };
                if (token)
                    headers["Authorization"] = `Bearer ${token}`;

                axios({
                    url,
                    method,
                    data,
                    params,
                    withCredentials: false,
                    cancelToken: cancelTokenSource.token,
                    headers,
                    onUploadProgress,
                })
                    .then((response) => resolve(response.data))
                    .catch((err) => handleError(err, attemptCount < maxAttemptCount)); // reattempt only if maxAttemptCount is not reached.
            };
            await call();
        });

        promise.disposed(() => {
            cancelTokenSource.cancel();
        });
        return promise;
    }

    get = <T>(path: string, queryParams?: Record<string, string | number>) => {
        return this.request<T>('GET', path, queryParams);
    };

    post = <T>(path: string, queryParams?: Record<string, string | number>, data?: Record<string, any>, onUploadProgress?: (progressEvent: AxiosProgressEvent) => void) => {
        return this.request<T>('POST', path, queryParams, data, onUploadProgress);
    };

    postStream = <T>(path: string, queryParams?: Record<string, string | number>, data?: Record<string, any>) => {
        return this.request<T>('POST', path, queryParams, data);
    };

    patch = <T>(path: string, queryParams?: Record<string, string | number>, data?: Record<string, any>) => {
        return this.request<T>('PATCH', path, queryParams, data);
    };

    delete = <T>(path: string, queryParams?: Record<string, string | number>, data?: Record<string, any>) => {
        return this.request<T>('DELETE', path, queryParams, data);
    };

    downloadDocument = async (path: string, contentType: string = 'application/octet-stream'): Promise<boolean> => {
        try {
            const baseUrl = process.env.REACT_APP_API_BASE_URL + "/";
            const url = `${baseUrl}${path}`;

            const response = await axios.get(url, {
                headers: {
                    'Authorization': `Bearer ${await this.getAccessToken()}`,
                    'Accept-Language': this.getAcceptLanguageValue(),
                },
                responseType: 'blob', // Set the response type to 'blob'
            });

            const blob = new Blob([response.data], {
                type: response.headers['content-type'] || contentType, // Use content-type from response headers or prop
            });

            // Create a download link for the Blob
            const downloadUrl = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;

            // Ensure filename is set correctly, falling back if necessary
            const filename = response.headers['content-disposition']
                ? response.headers['content-disposition'].split('filename=')[1].replace(/"/g, '') // Extract filename from content-disposition header
                : 'download'; // Fallback filename

            // Set the download attribute
            link.download = filename;

            // Append link to the body
            document.body.appendChild(link);

            link.click();

            // Clean up: remove link and revoke object URL
            document.body.removeChild(link);
            URL.revokeObjectURL(downloadUrl);

            return true;
        } catch (error) {
            console.error(fileUploadErrors.deleteError.message, error);
            return false;
        }
    };
}


export type HttpError = {
    status: number,
    statusText: string;
    data?: any,
    config?: any;
};