import { Suspense } from 'react';
import ErrorBoundary from '../error/ErrorBoundary';
import Loader from '../ui/loader/Loader';

export const wrapInSuspense = (component: JSX.Element) => {
    // Cannot be memoized
    return (
        <ErrorBoundary fallback={<>Unable to load component</>}>
            <Suspense fallback={<Loader isRelative />}>
                {component}
            </Suspense>
        </ErrorBoundary>

    );
};