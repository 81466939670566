import { CustomSelectOptionBaseType, CustomSelectOptionType, generateUniqueId, SelectChangeEventArgsType, SelectOptionType } from '@danfoss/mosaic-core';
import { DfSelectCustomEvent } from '@danfoss/mosaic-core/loader';
import { DfSelect } from '@danfoss/mosaic-react';
import { useCallback } from 'react';
import { BaseUIType } from '../../models/UITypes';
import { isNumber } from '../../helpers/typeHelpers';
import { withUIState } from '../../contexts/ui-state/withUIState';

export type SelectEventType = DfSelectCustomEvent<SelectChangeEventArgsType<false, false>>;

export type SelectProps = {
    className?: string;
    placeholder?: string;
    /** Sets default selection value */
    selectedOptionIndex?: number;
    options: CustomSelectOptionType[];
    onChange: (index?: CustomSelectOptionBaseType) => void;
    value?: string;
    isDisabled?: boolean;
    isReadonly?: boolean;
    isLoading?: boolean;
    showSelectedOptions?: boolean;
    getOptionLabel?: (option: CustomSelectOptionType) => string;
};

const Select = ({ testId, className, placeholder, value, isDisabled, isReadonly, isLoading, selectedOptionIndex, showSelectedOptions = false, options, onChange, getOptionLabel }: BaseUIType & SelectProps) => {
    const handleChange = useCallback((e: SelectEventType) => onChange(e.detail.value as CustomSelectOptionBaseType), [onChange]);

    return (
        <DfSelect
            className={className}
            placeholder={placeholder}
            options={options}
            isReadonly={isReadonly}
            aria-readonly={isReadonly}
            data-testid={testId}
            /*  isReadonly is needed in isDisabled as well, 
                since isReadonly wont do that for some reason.
                Only changes the style */
            disabled={isReadonly || isDisabled || isLoading}
            showSelectedOptions={showSelectedOptions}
            value={value}
            defaultSelectValue={isNumber(selectedOptionIndex) ? options?.[selectedOptionIndex] : undefined}
            onSelectChange={handleChange}
            getOptionLabel={getOptionLabel}
        />
    );
};

export const SelectStatic = withUIState(({ label, testId, value, options, addEmptyPlaceholder, isDisabled, isLoading, isReadonly, onChange }: { isReadonly?: boolean; label?: string, testId?: string, value?: string, isDisabled?: boolean, isLoading?: boolean, options: SelectOptionType[], addEmptyPlaceholder?: string, onChange: (value: string) => void; }) => {
    const id = generateUniqueId();

    return <fieldset className="df-form-field" data-testid={testId}>
        {label && <label className="df-field-label" htmlFor={id}>{label}</label>}
        <select disabled={isDisabled || isLoading || isReadonly} value={value} id={id} className="df-select" onChange={(e) => onChange(e.target.value)}>
            {addEmptyPlaceholder !== undefined && <option key={'initial'} value={''}>{addEmptyPlaceholder}</option>}
            {options.map(({ value, label }) => <option key={value} value={value}>{label}</option>)}
        </select>
    </fieldset>;
});

export default withUIState(Select);