import { FC } from 'react';
import { generateUniqueString } from '@danfoss/mosaic-core';
import { getClassNames } from '../../helpers/classHelpers';
import { withUIState } from '../../contexts/ui-state/withUIState';

type SwitchProps = {
    className?: string;
    label?: string;
    isDisabled?: boolean;
    isLoading?: boolean;
    isChecked?: boolean;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};
const Switch: FC<SwitchProps> = ({ className, isDisabled, isLoading, label, isChecked, onChange }) => {
    const uniqueId = generateUniqueString();
    return (
        <fieldset className={getClassNames([className, "df-switch", !label && "no-label"])}>
            <input type="checkbox" id={uniqueId} checked={isChecked} onChange={onChange} disabled={isDisabled || isLoading} />
            {label && <label htmlFor={uniqueId}>{label}</label>}
        </fieldset>
    );
};

export default withUIState(Switch);