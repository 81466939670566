import useLabels from './useLabels';
import { useCallback, useMemo, } from 'react';
import { rawLlmToUI } from '../helpers/transformHelpers';
import { useAppInfo } from '../contexts/app-info/AppInfoContext';
import { sortItemsBy } from '../helpers/listsHelpers';
import { PersonaOption } from '../models/types';
import { advancedAssistantKey, advancedAssistantRoute } from '../constants/consts';
import { SvgIcons } from '../ui/icon/icons/svg';

const getAdvancedPersona = (labels: ReturnType<typeof useLabels>): PersonaOption => ({
    key: advancedAssistantKey,
    route: advancedAssistantRoute,
    type: "chat",
    title: labels.advancedTitle,
    description: labels.advancedDescription,

    icon: SvgIcons.InstantMix,
    avatar: SvgIcons.InstantMix,

    versions: [{
        version: "1.0.0",
        date: "",
        highlights: [],
        revisions: [{
            label: "1.0.0",
            version: "1.0.0",
            build: 1,
            alpha: false,
            beta: false,
            documents: {
                enabled: true,
            },
        }]
    }]
});

export default function useInfo() {
    const labels = useLabels();
    const { llms, personas, access, documents: docs } = useAppInfo();

    const llmOptions = useMemo(() => {
        if (!llms) return [];
        return llms
            .map(rawLlmToUI)
            .sort((a, b) => a.sort - b.sort);
    }, [llms]);

    // Do not return this object directly in this hook's response, as it exposes all personas not taking into account the IAM
    const personaOptions = useMemo(() => {
        let items = [
            ...sortItemsBy(personas || [], "sort"),
            // Appending Advanced persona
            getAdvancedPersona(labels),
        ];

        // Populating document rules based on global document rules
        items.forEach((persona) => {
            persona.versions.forEach(version => {
                version.revisions.forEach(revision => {
                    if (!revision.documents?.enabled)
                        return;

                    revision.documents = {
                        ...docs,
                        ...(revision.documents ?? {})
                    };
                });
            });
        });

        return items;
    }, [docs, labels, personas]);

    const allowedPersonaOptions = useMemo(() => {
        return personaOptions.filter(p => p.key === advancedAssistantKey || !!access?.personas?.[p.key]);
    }, [personaOptions, access]);


    const getPersonaFromKey = useCallback((key: string) => personaOptions?.find(p => p.key === key), [personaOptions]);

    const getPersonaFromRouteKey = useCallback((key: string) => personaOptions?.find(p => p?.route === key), [personaOptions]);

    const getPersonaVersion = useCallback((persona?: string, version?: string, build?: number) => {
        const p = persona ? getPersonaFromKey(persona) : undefined;
        const v = version ? p?.versions.find(v => v.version === version) : p?.versions[0];
        const r = build ? v?.revisions.find(r => r.build === build) : v?.revisions[0];
        return {
            persona: p,
            version: v,
            revision: r
        };
    }, [getPersonaFromKey]);

    const getLlmFromKey = useCallback((key?: string) => key ? llms?.find(llm => llm.key === key) : undefined, [llms]);

    const getLlmLabelFromKey = useCallback((key: string) => getLlmFromKey(key)?.title || key, [getLlmFromKey]);

    const getPersonaLabelFromKey = useCallback((key: string) => getPersonaFromKey(key)?.title ?? key, [getPersonaFromKey]);

    return {
        llmOptions,
        allowedPersonaOptions,
        getPersonaVersion,
        getPersonaFromKey,
        getPersonaFromRouteKey,
        getLlmFromKey,
        getLlmLabelFromKey,
        getPersonaLabelFromKey
    };
}